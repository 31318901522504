import "../assets/css/landing.min.css";
import "../assets/css/landing.dark.min.css";
import "../assets/css/landing.light.min.css";
import { ReactElement } from "react";
import ReportLanding from "../layout/ReportLanding";
import EducationalGuideLanding from "../layout/EducationalGuideLanding";
import MenuMode from "../layout/MenuMode";
import InformativeGuideLanding from "../layout/InformativeGuideLanding";
import Footer from "../layout/Footer";

const Landing = (): ReactElement<HTMLElement> => {
  return (
    <>
      <MenuMode />
      <InformativeGuideLanding />
      <ReportLanding />
      <EducationalGuideLanding />
      <Footer />
    </>
  );
};

export default Landing;