import { ReactElement } from "react";
import Paragraph from "../components/Paragraph";
import Title from "../components/Title";
import { H2 } from "../dictionary/title.dictionary";
import ItemList from "../components/ItemList";
import CheckCircleIcon from "../components/svg/CheckCircleIcon";
import List from "../components/List";
import ContentCustom from "../components/ContentCustom";

interface ICards {
  title: string;
  content?: ReactElement;
  description?: string;
  legend?: string;
  type?: string;
}

interface IParagraphs {
  description?: string;
  mode?: string;
  isParagraph?: boolean;
  isTitle?: boolean;
  isList?: boolean;
  items?: string[];
  isCard?: boolean;
  cards?: ICards[];
  content?: ReactElement;
}

interface IInformativeGuideParagraphs {
  paragraphs: IParagraphs[];
}

const InformativeGuideParagraphs = (
  props: IInformativeGuideParagraphs
): ReactElement<HTMLDivElement> => {
  const { paragraphs } = props;

  return (
    <>
      {paragraphs.map((paragraph: IParagraphs, key: number) => (
        <>
          {paragraph.isTitle ? (
            <Title
              key={key}
              type={paragraph.mode as typeof H2}
              value={paragraph.description}
              className="subtitle"
            />
          ) : (
            <></>
          )}
          {paragraph.isParagraph ? (
            <Paragraph
              key={key}
              className={`description-${paragraph.mode}`}
              value={paragraph.description}
            >
              {paragraph.content}
            </Paragraph>
          ) : (
            <></>
          )}
          {paragraph.isList && paragraph.items ? (
            <List className={paragraph.mode}>
              {paragraph.items.map((item: string, key: number) => (
                <ItemList key={key}>
                  <>
                    <CheckCircleIcon fill="#0054B9" width={20} height={20} />
                    {item}
                  </>
                </ItemList>
              ))}
            </List>
          ) : (
            <></>
          )}
          {paragraph.isCard && paragraph.cards ? (
            <ContentCustom className="cards-defined">
              {paragraph.cards.map((card: ICards, key: number) => (
                <ContentCustom className="card-defined" key={key}>
                  <ContentCustom className="labels-card">
                    {card.legend ? (
                      <ContentCustom className="legend-card">
                        <>{card.legend}</>
                      </ContentCustom>
                    ) : (
                      <></>
                    )}
                    <ContentCustom className="label-card">
                      <>{card.type}</>
                    </ContentCustom>
                  </ContentCustom>
                  <Title type="h3" className="title-card" value={card.title} />
                  <Paragraph
                    className="description-card"
                    value={card.description}
                  >
                    {card.content}
                  </Paragraph>
                </ContentCustom>
              ))}
            </ContentCustom>
          ) : (
            <></>
          )}
        </>
      ))}
    </>
  );
};

export default InformativeGuideParagraphs;