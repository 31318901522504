import { ReactElement, useState } from "react";
import ContentCustom from "../components/ContentCustom";
import JsonMenu from "../json/menu.json";
import GridMenu from "../components/grid/GridMenu";
import SocialMedia from "./SocialMedia";
import Paragraph from "../components/Paragraph";
import { IItemMenu } from "../interfaces/card.interface";

const Footer = (): ReactElement<HTMLElement> => {
    const [date] = useState<Date>(new Date());

  return (
    <footer className="footer">
      <ContentCustom className="menu-footer">
        <GridMenu listMenu={JsonMenu.items as IItemMenu[]} />
      </ContentCustom>
      <SocialMedia />
      <Paragraph className="copyright">
        <>
          &copy; {date.getUTCFullYear()} Teamwork Chile. Todos los derechos
          reservados.
        </>
      </Paragraph>
    </footer>
  );
};

export default Footer;