import { ReactElement } from "react";
import ContentCustom from "../components/ContentCustom";
import CustomLink from "../components/CustomLink";
import GlobeIcon from "../components/svg/GlobeIcon";
import FbIcon from "../components/svg/FbIcon";
import IgIcon from "../components/svg/IgIcon";
import TiktokIcon from "../components/svg/TiktokIcon";
import LinkedinIcon from "../components/svg/LinkedinIcon";

const SocialMedia = (): ReactElement<HTMLDivElement> => {
  return (
    <ContentCustom className="social-media">
      <CustomLink className="item-social-media" uri="https://www.team-work.cl">
        <GlobeIcon width={24} height={24} fill="#0054B9" />
      </CustomLink>
      <CustomLink
        className="item-social-media"
        uri="https://www.instagram.com/teamworkchile"
      >
        <IgIcon width={24} height={24} fill="#0054B9" />
      </CustomLink>
      <CustomLink
        className="item-social-media"
        uri="https://www.facebook.com/TeamWorkChile"
      >
        <FbIcon width={24} height={24} fill="#0054B9" />
      </CustomLink>
      <CustomLink
        className="item-social-media"
        uri="https://www.tiktok.com/@teamworkchile"
      >
        <TiktokIcon width={24} height={24} fill="#0054B9" />
      </CustomLink>
      <CustomLink
        className="item-social-media"
        uri="https://www.linkedin.com/company/team-work-consultora-rrhh/?originalSubdomain=cl"
      >
        <LinkedinIcon width={24} height={24} fill="#0054B9" />
      </CustomLink>
    </ContentCustom>
  );
};

export default SocialMedia;