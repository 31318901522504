import { ReactElement } from "react";
import ContentCustom from "../components/ContentCustom";
import Title from "../components/Title";
import Paragraph from "../components/Paragraph";
import LinkRedirect from "../components/LinkRedirect";
import Button from "../components/Button";
import InformativeGuidePdf from "../documents/guia-informativa.pdf";

interface IHandleClickDownloadFile {
  blob: string;
  name: string;
}

const InformativeGuideLanding = (): ReactElement<HTMLDivElement> => {
  const handleClickDownloadFile = (prompts: IHandleClickDownloadFile): void => {
    const { blob, name } = prompts;
    const a = document.createElement("a");
    a.setAttribute("download", name);
    a.setAttribute("href", blob);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <ContentCustom className="informative-guide-landing">
      <Title
        className="title"
        value="Bienvenido a la guía informativa sobre la ley Karin"
      />
      <Paragraph
        className="description"
        value="Encuentra aquí
        recursos educativos esenciales, información detallada sobre cómo
        presentar denuncias a través de nuestro canal especializado, y una guía
        completa para entender y aplicar esta legislación clave en la protección
        de derechos."
      />
      <ContentCustom className="control-button">
        <Button
          title="Descargar Guía"
          className="button-ebook"
          onClick={() =>
            handleClickDownloadFile({
              blob: InformativeGuidePdf,
              name: "guia-informativa.pdf",
            })
          }
        />
        <LinkRedirect uri="/guia-informativa" title="Conocer más" />
      </ContentCustom>
    </ContentCustom>
  );
};

export default InformativeGuideLanding;