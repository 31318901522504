import { ReactElement } from "react";
import RedirectIcon from "./svg/RedirectIcon";
import CustomLink from "./CustomLink";
import { DIRECTION_LEFT, DIRECTION_RIGHT } from "../dictionary/icon.dictionary";

interface ILinkRedirect {
  title: string;
  uri: string;
  direction?: typeof DIRECTION_LEFT | typeof DIRECTION_RIGHT;
}

const LinkRedirect = (props: ILinkRedirect): ReactElement<HTMLLinkElement> => {
  const { title, uri, direction } = props;

  const icon = (): ReactElement<HTMLOrSVGElement> => (
    <RedirectIcon
      width={24}
      height={24}
      fill="#0054B9"
      className={`direction-${direction ?? DIRECTION_RIGHT}`}
    />
  );

  return (
    <CustomLink uri={uri} className="card-button">
      {direction && direction === DIRECTION_LEFT ? icon() : <></>}
      <>{title}</>
      {!direction || direction === DIRECTION_RIGHT ? icon() : <></>}
    </CustomLink>
  );
};

export default LinkRedirect;