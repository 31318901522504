import { ReactElement, useState } from "react";
import ClassName from "../../class/ClassName";
import { IIConSvg } from "../../interfaces/icon.interface";

const LogoIcon = (props: IIConSvg): ReactElement<HTMLOrSVGElement> => {
  const { width, height, fill, className } = props;
  const [classNames] = useState<ClassName>(new ClassName());

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 218 42"
      className={classNames.transformArrayToString({ className })}
    >
      <path
        d="M0 15.4945V21.2551H7.10432V41.6166H13.81V21.2551H20.9079V15.4945H0Z"
        fill={fill}
        className="letter-t"
      />
      <path
        d="M84.1267 5.71566C84.1267 6.84719 83.7908 7.95326 83.1616 8.89373C82.5324 9.83421 81.6383 10.5668 80.5924 10.9986C79.5465 11.4304 78.396 11.5421 77.2866 11.3195C76.1771 11.0969 75.1588 10.5499 74.3605 9.74802C73.5621 8.9461 73.0198 7.92528 72.8022 6.81487C72.5846 5.70446 72.7014 4.55445 73.138 3.51051C73.5745 2.46658 74.3111 1.57572 75.2544 0.950784C76.1977 0.325848 77.3053 -0.00503337 78.4368 5.7882e-05C79.9527 5.7882e-05 81.4065 0.602236 82.4784 1.67412C83.5502 2.746 84.1524 4.19979 84.1524 5.71566"
        fill={fill}
        className="letter-e"
      />
      <path
        d="M99.0104 5.71566C99.0117 6.84682 98.6777 7.95298 98.0505 8.89438C97.4234 9.83579 96.5313 10.5702 95.487 11.0048C94.4427 11.4395 93.2929 11.5548 92.1831 11.3363C91.0732 11.1178 90.053 10.5753 89.2514 9.77721C88.4497 8.97916 87.9026 7.96141 87.6791 6.85254C87.4557 5.74368 87.5658 4.59345 87.9958 3.54718C88.4257 2.50091 89.1561 1.60555 90.0947 0.974206C91.0333 0.342864 92.1379 0.00387149 93.2691 5.7882e-05C94.0218 -0.00332908 94.7678 0.14201 95.4643 0.427729C96.1607 0.713448 96.7938 1.13392 97.3273 1.665C97.8607 2.19608 98.284 2.82731 98.5729 3.52244C98.8617 4.21757 99.0104 4.96291 99.0104 5.71566Z"
        fill={fill}
        className="letter-a"
      />
      <path
        d="M60.3771 28.2887V26.1607C60.3771 22.6053 58.834 20.7472 55.3172 20.7472C51.8004 20.7472 50.2574 22.6053 50.2574 26.1607V28.2887H60.3771ZM44.0146 26.1607C44.0146 19.1271 48.0072 14.8195 55.3558 14.8195C62.7044 14.8195 66.7742 19.1271 66.7742 26.1607V41.263H60.3449V33.7086H50.2574V41.263H44.0146V26.1607Z"
        fill={fill}
        className="letter-m"
      />
      <path
        d="M23.4668 41.6166V15.4945H41.4558V20.9401H30.1725V25.9806H39.9642V31.1305H30.1725V36.1646H41.4558V41.6166H23.4668Z"
        fill={fill}
        className="symbol-point"
      />
      <path
        d="M69.333 24.8812C69.333 17.9248 73.3899 14.8195 78.6426 14.8195C81.78 14.8195 84.2874 16.0539 85.8369 18.4006C87.3927 16.0539 89.8937 14.8195 93.0376 14.8195C98.2903 14.8195 102.347 17.9248 102.347 24.8812V41.263H95.9179V24.8812C95.9179 21.8595 94.4456 20.7858 92.4911 20.7858C90.5367 20.7858 89.0708 21.8595 89.0708 24.8812V41.263H82.6415V24.8812C82.6415 21.8595 81.1307 20.7858 79.2212 20.7858C77.3117 20.7858 75.8008 21.8595 75.8008 24.8812V41.263H69.3716L69.333 24.8812Z"
        fill={fill}
        className="symbol-point"
      />
      <path
        d="M160.828 28.2327C160.828 23.9251 157.78 20.6591 153.62 20.6591C149.461 20.6591 146.452 23.9251 146.452 28.2327C146.452 32.5403 149.461 35.8128 153.62 35.8128C157.78 35.8128 160.828 32.5403 160.828 28.2327ZM139.843 28.2327C139.843 20.9162 145.629 14.8277 153.62 14.8277C161.612 14.8277 167.405 20.9162 167.405 28.2327C167.405 35.5492 161.618 41.6441 153.62 41.6441C145.622 41.6441 139.843 35.5492 139.843 28.2327Z"
        fill={fill}
        className="letter-w"
      />
      <path
        d="M104.906 31.5759V15.1428H111.335V31.5759C111.335 34.5977 112.808 35.6713 114.756 35.6713C116.704 35.6713 118.176 34.5977 118.176 31.5759V15.1428H124.605V31.5759C124.605 34.5977 126.116 35.6713 128.026 35.6713C129.935 35.6713 131.446 34.5977 131.446 31.5759V15.1428H137.875V31.5759C137.875 38.5388 133.818 41.6377 128.572 41.6377C125.428 41.6377 122.921 40.4033 121.371 38.0566C119.822 40.4033 117.314 41.6377 114.177 41.6377C108.924 41.6377 104.867 38.5388 104.867 31.5759"
        fill={fill}
        className="letter-o"
      />
      <path
        d="M200.798 41.6248H194.08V15.5028H200.798V27.5448L207.87 15.5028H214.583V16.6022L207.266 28.5606L210.024 33.659C211.394 36.3657 212.069 36.655 213.451 36.655C214.112 36.592 214.745 36.3594 215.29 35.9799C216.003 37.5551 216.756 39.0596 217.469 40.5961C215.868 41.552 214.03 42.0377 212.165 41.9977C209.008 41.9977 206.154 40.384 204.386 36.5522L200.818 29.8722L200.798 41.6248Z"
        fill={fill}
        className="letter-r"
      />
      <path
        d="M179.376 20.164C182.295 20.164 183.825 21.1155 183.825 24.2016C183.825 27.0883 182.295 28.2327 179.376 28.2327H176.065V20.164H179.376ZM188.313 31.0037C190.107 29.1727 191.105 26.7071 191.09 24.1437C191.1 22.9177 190.865 21.702 190.4 20.5675C189.935 19.433 189.249 18.4023 188.382 17.5353C187.516 16.6684 186.485 15.9825 185.35 15.5177C184.216 15.0528 183 14.8183 181.774 14.8277H169.327V41.2712H176.071V33.6976H179.742C182.668 33.6976 184.198 34.7905 184.198 37.6516V41.2712H191.097V37.6323C191.074 35.1443 190.077 32.7644 188.319 31.0037"
        fill={fill}
        className="letter-k"
      />
    </svg>
  );
};

export default LogoIcon;