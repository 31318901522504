import { ReactElement, useState } from "react";
import ClassName from "../class/ClassName";

interface IHeaders {
  children?: ReactElement | ReactElement[];
  className?: string[] | string;
}

const Headers = (props: IHeaders): ReactElement<HTMLElement> => {
  const { children, className } = props;
  const [classNames] = useState<ClassName>(new ClassName());

  return (
    <header className={classNames.transformArrayToString({ className })}>
      {children}
    </header>
  );
};

export default Headers;