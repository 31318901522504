import { ReactElement, useEffect, useState } from "react";
import Theme from "../class/Theme";
import Menu from "./Menu";
import ReactGA from 'react-ga4';

interface IMenuMode {
  titlePage?: string;
}

const MenuMode = (props: IMenuMode): ReactElement<HTMLElement> => {
  const { titlePage } = props;
  const [theme] = useState<Theme>(new Theme());

  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: titlePage ?? "Ley Karin - Teamwork Chile",
  });

  useEffect(() => {
    const body = document.querySelector("body");
    document.title = titlePage ?? "Ley Karin - Teamwork Chile";

    if (body) {
      body.classList.add(theme.getTheme());
    }
  }, [titlePage, theme]);

  return (
    <Menu
      theme={theme.getTheme()}
      saveToggleThemeStorage={theme.saveToggleThemeStorage}
    />
  );
};

export default MenuMode;