import { ReactElement, useEffect, useState } from "react";
import JsonGlossary from "../json/glossary.json";
import Card from "../components/CardGlossary";

interface ICardGlossary {
  title: string;
  description: string;
}

const Glossary = (): ReactElement<HTMLElement> => {
  const [cardsGlossary, setCardsGlossary] = useState<ICardGlossary[]>([]);

  useEffect(() => {
    if(JsonGlossary){
        setCardsGlossary(JsonGlossary.itemsGlossary);
    }
  }, []);

  return (
    <section id="glossary" className="glossary">
      <h3 className="subtitle">Glosario ley Karin</h3>
      <h2 className="title">
        Definiciones claves para comprender la guia educativa
      </h2>
      <section className="cards">
        {cardsGlossary.map((card: ICardGlossary, key: number) => (
          <Card key={key} title={card.title} description={card.description} />
        ))}
      </section>
    </section>
  );
};

export default Glossary;