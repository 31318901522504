import { ReactElement, useState } from "react";
import ClassName from "../class/ClassName";

interface IDiv {
  children?: ReactElement | ReactElement[];
  className?: string | string[];
  onClick?: () => void;
}

const Div = (props: IDiv): ReactElement<HTMLDivElement> => {
  const { children, className, onClick } = props;
  const [classNames] = useState<ClassName>(new ClassName());

  return (
    <div className={classNames.transformArrayToString({ className })} onClick={onClick}>
      {children}
    </div>
  );
};

export default Div;