import Modulo1Pdf from "../documents/archivo1.pdf";
import Modilo2Pdf from "../documents/archivo2.pdf";
import Modilo3Pdf from "../documents/archivo3.pdf";
import Modilo4Pdf from "../documents/archivo4.pdf";

const academicModules = [
  {
    id: "module-1",
    indexModule: "Módulo I",
    title:
      "Fundamentos e impacto del acoso laboral, acoso sexual y la violencia en el trabajo",
    course: {
      uri: "https://view.genially.com/669aa7909c64fa0477e39ed4",
      name: "TEAMCLASS - M1 LEY KARIN",
    },
    pdf: {
      blob: Modulo1Pdf,
      name: "Modulo 1.pdf",
    },
  },
  {
    id: "module-2",
    indexModule: "Módulo II",
    title: "Identificación y prevención del acoso y la violencia en el trabajo",
    course: {
      uri: "https://view.genially.com/669bedf8a0c4cb116dd63584",
      name: "MÓDULO 2 - LEY KARIN",
    },
    pdf: {
      blob: Modilo2Pdf,
      name: "Modulo 2.pdf",
    },
  },
  {
    id: "module-3",
    indexModule: "Módulo III",
    title: "Marco legal y normativo en el entorno laboral (Ley Karin)",
    course: {
      uri: "https://view.genially.com/669bf884079a5f2933112348",
      name: "MÓDULO 3 - LEY KARIN",
    },
    pdf: {
      blob: Modilo3Pdf,
      name: "Modulo 3.pdf",
    },
  },
  {
    id: "module-4",
    indexModule: "Módulo IV",
    title: "Protocolo prevención y acción",
    course: {
      uri: "https://view.genially.com/669c0170079a5f2933176c30",
      name: "MÓDULO 4 - LEY KARIN",
    },
    pdf: {
      blob: Modilo4Pdf,
      name: "Modulo 4.pdf",
    },
  },
];

export default academicModules;
