import "../assets/css/landing.min.css";
import "../assets/css/landing.dark.min.css";
import "../assets/css/landing.light.min.css";
import Home from "../layout/Home";
import Glossary from "../layout/Glossary";
import AcademicModules from "../layout/AcademicModules";
import { ReactElement } from "react";
import MenuMode from "../layout/MenuMode";
import Footer from "../layout/Footer";

const EducationalGuide = (): ReactElement<HTMLElement> => {
  return (
    <>
      <MenuMode titlePage="Guia Educativa Ley Karin - Teamwork Chile" />
      <Home />
      <Glossary />
      <AcademicModules />
      <Footer />
    </>
  );
};

export default EducationalGuide;
