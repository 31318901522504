import ChannelIcon from "../components/svg/ChannelIcon";
import DownloadIcon from "../components/svg/DownloadIcon";
import GuideIcon from "../components/svg/GuideIcon";
import HomeIcon from "../components/svg/HomeIcon";
import MediaIcon from "../components/svg/MediaIcon";

const menu = {
  items: [
    {
      title: "Inicio",
      uri: "/",
      icon: <HomeIcon fill="#000" width={24} height={24} />,
      description: "Página principal",
    },
    {
      title: "Guía informativa",
      uri: "/guia-informativa",
      icon: <GuideIcon fill="#000" width={24} height={24} />,
      description: "Guía informativa de la ley Karin",
    },
    {
      isDivider: true,
      isHiddenPrincipalMenu: true,
    },
    {
      title: "Descargar guía informativa",
      uri: "",
      icon: <DownloadIcon fill="#000" width={30} height={30} />,
      description: "Descargar guía informativa de la ley Karin en formato PDF",
      isHiddenPrincipalMenu: true,
    },
    {
      isDivider: true,
      isHiddenPrincipalMenu: true,
    },
    {
      title: "Canal de denuncias",
      uri: "https://teamwork.buk.cl/cul_partner_complaint/tickets/new",
      icon: <ChannelIcon fill="#000" width={24} height={24} />,
      description: "Canal de denuncias",
      target: "_blank",
    },
    {
      title: "Guía educativa",
      uri: "/guia-educativa",
      icon: <GuideIcon fill="#000" width={24} height={24} />,
      description: "Guía educativa de la ley Karin",
    },
    {
      isDivider: true,
      isHiddenPrincipalMenu: true,
    },
    {
      title: "Módulo I: Contenido Multimedia",
      uri: "https://view.genially.com/669aa7909c64fa0477e39ed4",
      icon: <MediaIcon fill="#000" width={24} height={24} />,
      target: "_blank",
      isHiddenPrincipalMenu: true,
      description:
        "Contenido multimedia fundamentos e impacto del acoso laboral, acoso sexual y la violencia en el trabajo",
    },
    {
      isDivider: true,
      isHiddenPrincipalMenu: true,
    },
    {
      title: "Módulo I: Descargar",
      icon: <DownloadIcon fill="#000" width={30} height={30} />,
      description:
        "Descargar fundamentos e impacto del acoso laboral, acoso sexual y la violencia en el trabajo en formato PDF",
      isHiddenPrincipalMenu: true,
    },
  ],
};

export default menu;