import { ReactElement } from "react";
import { IContent } from "../interfaces/content.interface";
import Div from "./Div";

const ContentMiddle = (props: IContent): ReactElement<HTMLDivElement> => {
  const { children } = props;

  return <Div className="middle">{children}</Div>;
};

export default ContentMiddle;