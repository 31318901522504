import { ReactElement } from "react";
import EducationalGuide from "./containers/EducationalGuide";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Landing from "./containers/Landing";
import Error404 from "./containers/Error404";
import InformativeGuide from "./containers/InformativeGuide";
import ReactGA from "react-ga4";

const App = (): ReactElement => {
  ReactGA.initialize("G-T398S4BJN6");

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/guia-educativa" element={<EducationalGuide />} />
        <Route path="/guia-informativa" element={<InformativeGuide />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </Router>
  );
};

export default App;
