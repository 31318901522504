import { ReactElement } from "react";
import { IItemMenu } from "../interfaces/card.interface";
import CustomLink from "./CustomLink";
import ContentCustom from "./ContentCustom";
import ContentLeft from "./ContentLeft";
import ContentRight from "./ContentRight";
import Title from "./Title";
import Paragraph from "./Paragraph";

const ItemMenuResponsive = (props: IItemMenu): ReactElement<HTMLLinkElement> => {
  const { uri, title, description, icon, target } = props;

  return (
    <CustomLink uri={uri} className="item-menu" target={target}>
      <ContentCustom className="item-menu-content">
        <ContentLeft>
          <ContentCustom className="item-menu-icon">
            <>{icon}</>
          </ContentCustom>
        </ContentLeft>
        <ContentRight>
          <Title className="item-menu-title" value={title} type="h3" />
          <Paragraph className="item-menu-description" value={description} />
        </ContentRight>
      </ContentCustom>
    </CustomLink>
  );
};

export default ItemMenuResponsive;
