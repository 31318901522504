const informativeGuide = {
  legends: "Guía informativa",
  title: "GUÍA SOBRE LA LEY N°21.643 “LEY KARIN”",
  paragraphs: [
    {
      description:
        "La empresa TEAMWORK RECURSOS HUMANOS LTDA. pone a disposición de sus trabajadores la presente guía informativa, el cual contiene los conceptos básicos y que todo trabajador debe conocer y manejar sobre la Ley N°21.643, mejor conocida como “Ley Karin”. Por lo que, a continuación, encontrará definiciones, principio, derechos, deberes y los canales de denuncia que debe conocer todo trabajador y que lo orientarán de mejor manera para poder saber, cómo detectarlas, qué hacer, cómo actuar y principalmente como prevenir y erradicar este tipo de conductas al interior de su lugar de trabajo.",
      mode: "primary",
      isParagraph: true,
    },
    {
      description: "¿Cuándo entra en vigencia la Ley Karin?",
      mode: "h2",
      isTitle: true,
    },
    {
      description:
        "La presente Ley entró en vigencia el pasado 1 de agosto de 2024",
      mode: "secondary",
      isParagraph: true,
    },
    {
      description: "¿Qué implicancias tiene la presente Ley?",
      mode: "h2",
      isTitle: true,
    },
    {
      description:
        "Esta Ley establece la obligación de las empresas de implementar políticas y prácticas orientadas a la prevención de riesgos psicosociales, así como la promoción de la salud mental y el bienestar de los trabajadores.",
      mode: "secondary",
      isParagraph: true,
    },
    {
      description:
        "¿CUÁLES SON LOS PRINCIPIOS RECTORES DEL PROCEDIMIENTO DE INVESTIGACIÓN?",
      mode: "h2",
      isTitle: true,
    },
    {
      items: [
        "Perspectiva de género",
        "No discriminación",
        "No revictimización",
        "No victimización secundaria",
        "Confidencialidad",
        "Imparcialidad",
        "Celeridad",
        "Razonabilidad",
        "Debido proceso",
        "Colaboración",
      ],
      isList: true,
      mode: "list",
    },
    {
      description: "DEFINICIONES",
      mode: "h2",
      isTitle: true,
    },
    {
      description:
        "La presente Ley, establece las siguientes definiciones respecto de qué se entiende por acoso laboral, acoso sexual y violencia en el trabajo ejercidos por terceros ajenos a la relación laboral, con el fin de que los trabajadores sepan y detecten cuando están frente a este tipo de situaciones, en este contexto, podemos entender por:",
      mode: "secondary",
      isParagraph: true,
    },
    {
      cards: [
        {
          title: "Acoso laboral",
          type: "Definición",
          content: (
            <>
              Es{" "}
              <b>
                toda conducta que constituya agresión u hostigamiento ejercida
                por el empleador o por uno o más trabajadores, en contra de otro
                u otros trabajadores, por cualquier medio,
              </b>{" "}
              ya sea <u>una sola vez o de manera reiterada,</u> y que tenga como
              resultado para el o los afectados su menoscabo, maltrato o
              humillación, o bien que amenace o perjudique su situación laboral
              o sus oportunidades en el empleo.
            </>
          ),
        },
        {
          title: "Acoso sexual",
          type: "Definición",
          content: (
            <>
              Es la{" "}
              <b>
                conducta de una persona que, en forma indebida y por cualquier
                medio, hace requerimientos de carácter sexual,
              </b>{" "}
              no consentidos por quien los recibe y que amenacen o perjudiquen
              su situación laboral o sus oportunidades en el empleo.
            </>
          ),
        },
        {
          title: "Violencia en el trabajo",
          type: "Definición",
          content: (
            <>
              Concepto referido a la{" "}
              <b>
                violencia ejercida por terceros ajenos a la relación laboral,
              </b>{" "}
              entendiéndose por tal aquellas conductas que afecten a las
              personas trabajadoras, con ocasión de la prestación de servicios,{" "}
              <b>por parte de clientes, proveedores o usuarios, entre otros.</b>
            </>
          ),
        },
      ],
      isCard: true,
    },
    {
      content: (
        <>
          Adicionalmente, se amplía la prohibición de discriminación en el
          trabajo ya no solo al catálogo de motivos previsto en el artículo 2°
          del Código del Trabajo. Es decir,{" "}
          <u>
            se prohíbe distinguir, excluir o preferir por cualquier motivo que
            tenga por objeto anular o alterar la igualdad de oportunidades o de
            trato en el empleo y la ocupación.
          </u>
        </>
      ),
      mode: "secondary",
      isParagraph: true,
    },
    {
      description: "¿CÓMO PUEDO PRESENTAR UNA DENUNCIA?",
      mode: "h2",
      isTitle: true,
    },
    {
      content: (
        <>
          La persona afectada por acoso sexual, laboral o de violencia en el
          trabajo podrá realizar su denuncia de <b>forma verbal o escrita,</b>{" "}
          ante el empleador o ante la Dirección del Trabajo de manera presencial
          o electrónica, debiendo recibir un comprobante de la gestión
          realizada. En los casos que la denuncia sea realizada de manera
          verbal, se debe realizar un levantamiento de un acta donde conste la
          denuncia para así contar con certeza respecto del contenido de la
          denuncia.
        </>
      ),
      isParagraph: true,
      mode: "secondary",
    },
    {
      description: "PROCEDIMIENTO, PLAZOS Y MEDIDAS DE RESGUARDO",
      mode: "h2",
      isTitle: true,
    },
    {
      cards: [
        {
          title: "Denuncia",
          type: "Procedimiento",
          legend: "I.-",
          content: (
            <>
              El procedimiento se origina por una denuncia que realiza uno o más
              trabajadores de manera escrita o verbal ante su empleador o la
              Inspección del Trabajo.
            </>
          ),
        },
        {
          title: "Medidas de resguardo",
          type: "Procedimiento",
          legend: "II.-",
          content: (
            <>
              Son aquellos actos que una vez recibida una denuncia, el empleador
              debe tomar para resguardar la integridad física y psíquica del
              afectado (denunciante). Entre ellas podemos encontrar a modo de
              ejemplo, separación de espacios físicos de trabajo, redistribución
              de la jornada, atención psicológica, etc., las cuales pueden ser
              modificadas en caso de ser necesario.
            </>
          ),
        },
        {
          title: "Investigación",
          type: "Procedimiento",
          legend: "III.-",
          content: (
            <>
              Una vez identificada las partes que integran el procedimiento
              (Denunciante y Denunciado), en el caso que el empleador realice la
              investigación éste debe designar a la persona a cargo. En esta
              etapa del proceso se reciben los medios probatorios, te toman
              declaraciones y se adoptan todas aquellas medidas con el fin de
              obtener la mayor información posibles y determinar la veracidad de
              la denuncia.
            </>
          ),
        },
        {
          title: "Elaboración del informe",
          type: "Procedimiento",
          legend: "IV.-",
          content: (
            <>
              Una vez concluida la etapa investigativa, la persona designada
              para realizar la investigación debe elaborar un informe con las
              conclusiones y medidas a adoptar de conformidad a la prueba
              recibida y a la investigación realizada.
            </>
          ),
        },
        {
          title: "Adopción de una sanción",
          type: "Procedimiento",
          legend: "V.-",
          content: (
            <>
              Terminada la etapa investigativa y emitido el informe, éste debe
              contener las medidas de resguardo o sanción que se aplicarán al
              denunciado en caso de ser encontrado culpable y que se estimen
              pertinentes, las cuales pueden llegar incluso a la desvinculación
              del trabajador.
            </>
          ),
        },
        {
          title: "Déposito del informe",
          type: "Procedimiento",
          legend: "VI.-",
          content: (
            <>
              Concluida la investigación este debe ser entregado (depositado) en
              la Dirección del Trabajo, la cual se debe pronunciar, y
              transcurrido el plazo en caso de que la Dirección no se pronuncie
              se entiende que se aceptan las conclusiones señaladas por el
              empleador.
            </>
          ),
        },
      ],
      isCard: true,
    },
    {
      cards: [
        {
          title: "Plazo de la investigación",
          type: "Plazos",
          content: (
            <>
              La investigación deberá concluirse en el plazo de <b>30 días</b>{" "}
              administrativos (de lunes a viernes) contados desde la
              presentación de la denuncia o desde la fecha de recepción de la
              derivación por el empleador a la Dirección del Trabajo. Para
              efectos del cómputo del plazo, en caso de derivación, la Dirección
              del Trabajo deberá emitir un certificado de recepción.
            </>
          ),
        },
        {
          title:
            "Remisión del informe de investigación a la Dirección del Trabajo",
          type: "Plazos",
          content: (
            <>
              El empleador dentro del plazo de <b>2 días</b> hábiles de
              finalizada la investigación interna remitirá el informe y sus
              conclusiones de manera electrónica a la Dirección del Trabajo.
            </>
          ),
        },
        {
          title: "Adopción de medidas o sanciones del informe por el empleador",
          type: "Plazos",
          content: (
            <>
              Deberá disponer y aplicar las medidas o sanciones que correspondan
              dentro de los siguientes <b>15 días</b> corridos, informando a la
              persona denunciante como a la denunciada. En caso que la Dirección
              del Trabajo no se pronuncie sobre la investigación interna, el
              empleador deberá disponer y aplicar las medidas o sanciones que
              correspondan, según su informe de investigación, dentro de los{" "}
              <b>15 días</b> corridos, una vez transcurrido <b>30 días</b> desde
              la remisión del informe de investigación a la Dirección del
              Trabajo.
            </>
          ),
        },
      ],
      isCard: true,
    },
    {
      description: "¿DÓNDE PUEDO EFECTUAR UNA DENUNCIA?",
      mode: "h2",
      isTitle: true,
    },
    {
      description: "Las denuncias por acoso laboral, sexual o violencia en el trabajo se pueden realizar al empleador o la Dirección del Trabajo, y en los casos que la denuncia se realice al empleador, ésta se puede realizar a través del correo electrónico teapoyo@team-work.cl, el cual siempre garantizará la confidencialidad de la denuncia. En aquellos casos que desee efectuar una denuncia verbal, lo debe señalar a través del correo indicado anteriormente y se le designará un investigador que levantará la respectiva acta de denuncia.",
      mode: "secondary",
      isParagraph: true,
    },
  ],
};

export default informativeGuide;