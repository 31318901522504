import { ReactElement, useState } from "react";
import ClassName from "../class/ClassName";

interface IList {
  children: ReactElement<HTMLLIElement>[] | ReactElement<HTMLLIElement>;
  className?: string | string[];
}

const List = (props: IList): ReactElement<HTMLUListElement> => {
  const { children, className } = props;
  const [classNames] = useState<ClassName>(new ClassName());

  return (
    <ul className={classNames.transformArrayToString({ className })}>
      {children}
    </ul>
  );
};

export default List;