import { ReactElement, useEffect, useState } from "react";
import { IItemMenu } from "../../interfaces/card.interface";
import ItemMenu from "../ItemMenu";

interface IGridMenu {
  listMenu: IItemMenu[];
}

const GridMenu = (props: IGridMenu): ReactElement<HTMLLinkElement> => {
  const { listMenu } = props;
  const [list, setList] = useState<IItemMenu[]>([]);

  useEffect(() => {
    if (typeof listMenu == "object") {
      setList(
        listMenu.filter((item: IItemMenu) => !item.isHiddenPrincipalMenu)
      );
    }

    return () => {
      setList([]);
    };
  }, [listMenu]);

  return (
    <>
      {list.map((item: IItemMenu, key: number) => (
        <ItemMenu
          key={key}
          title={item.title}
          uri={item.uri}
          target={item.target}
          className="item-menu"
        />
      ))}
    </>
  );
};

export default GridMenu;