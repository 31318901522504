import { ReactElement, useEffect, useState } from "react";
import LogoImg from "../assets/images/logo.svg";
import { DARK, LIGHT } from "../dictionary/theme.dictionary";
import JsonMenu from "../json/menu.json";
import Headers from "../components/Headers";
import ContentLeft from "../components/ContentLeft";
import ContentMiddle from "../components/ContentMiddle";
import ContentRight from "../components/ContentRight";
import GridMenu from "../components/grid/GridMenu";
import DarkMode from "../components/DarkMode";
import CustomLink from "../components/CustomLink";
import MenuIcon from "../components/svg/MenuIcon";
import Button from "../components/Button";
import ContentCustom from "../components/ContentCustom";
import GridMenuResponsive from "../components/grid/GridMenuResponsive";
import CloseIcon from "../components/svg/CloseIcon";
import ContentCustomEmpty from "../components/ContentCustomEmpty";
import { IItemMenu } from "../interfaces/card.interface";
import { SCROLL_AUTO, SCROLL_HIDDEN } from "../dictionary/scroll.dictionary";
import LogoIcon from "../components/svg/LogoIcon";

interface IMenu {
  theme: typeof LIGHT | typeof DARK;
  saveToggleThemeStorage: () => void;
}

const Menu = (props: IMenu): ReactElement<HTMLElement> => {
  const { theme, saveToggleThemeStorage } = props;
  const [themeMode, setThemeMode] = useState<
    typeof DARK | typeof LIGHT | undefined
  >(undefined);
  const [isEnableMenuResponsive, setIsEnableMenuResponsive] = useState<boolean>(false);

  useEffect(() => {
    setThemeMode(theme);
  }, [theme]);

  useEffect(() => {
    const body = document.querySelector("body");

    if (body) {
      body.style.overflow = isEnableMenuResponsive
        ? SCROLL_HIDDEN
        : SCROLL_AUTO;
    }
  }, [isEnableMenuResponsive]);

  return (
    <Headers className="menu">
      <ContentLeft>
        <ContentCustom className="menu-responsive">
          <Button
            className="button-menu-responsive"
            onClick={() => setIsEnableMenuResponsive(!isEnableMenuResponsive)}
          >
            {!isEnableMenuResponsive ? (
              <MenuIcon fill="#000" width={24} height={24} />
            ) : (
              <CloseIcon fill="#000" width={24} height={24} />
            )}
          </Button>
          {isEnableMenuResponsive ? (
            <ContentCustom className="content-menu-responsive">
              <GridMenuResponsive listMenu={JsonMenu.items as IItemMenu[]} />
            </ContentCustom>
          ) : (
            <></>
          )}
        </ContentCustom>
        {isEnableMenuResponsive ? (
          <ContentCustomEmpty
            className="overlay-menu-responsive"
            onClick={() => setIsEnableMenuResponsive(false)}
          />
        ) : (
          <></>
        )}
        <CustomLink uri="/" className="icon-logo">
          <LogoIcon width={150} height={42} fill="#FF4900" />
        </CustomLink>
      </ContentLeft>
      <ContentMiddle>
        <GridMenu listMenu={JsonMenu.items as IItemMenu[]} />
      </ContentMiddle>
      <ContentRight>
        <DarkMode theme={themeMode} saveChangeTheme={saveToggleThemeStorage} />
      </ContentRight>
    </Headers>
  );
};

export default Menu;
