import { ReactElement, useEffect, useState } from "react";
import { IItemMenu } from "../../interfaces/card.interface";
import ItemMenuResponsive from "../ItemMenuResponsive";
import ContentCustom from "../ContentCustom";
import ContentCustomEmpty from "../ContentCustomEmpty";

interface IGridMenu {
  listMenu: IItemMenu[];
}

const GridMenuResponsive = (
  props: IGridMenu
): ReactElement<HTMLLinkElement> => {
  const { listMenu } = props;
  const [list, setList] = useState<IItemMenu[]>([]);

  useEffect(() => {
    if (typeof listMenu == "object") {
      setList(listMenu);
    }

    return () => {
      setList([]);
    };
  }, [listMenu]);

  return (
    <ContentCustom className="scroll-menu-overlay">
      {list.map((item: IItemMenu, key: number) => (
        <>
          {!item.isDivider ? (
            <ItemMenuResponsive
              key={key}
              title={item.title}
              uri={item.uri}
              description={item.description}
              icon={item.icon}
              target={item.target}
            />
          ) : (
            <></>
          )}
          {item.isDivider ? (
            <ContentCustomEmpty className="divider-item-menu" />
          ) : (
            <></>
          )}
        </>
      ))}
    </ContentCustom>
  );
};

export default GridMenuResponsive;