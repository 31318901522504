import { ReactElement } from "react";
import { IContentCustom } from "../interfaces/content.interface";
import Div from "./Div";

const ContentCustom = (props: IContentCustom): ReactElement<HTMLDivElement> => {
  const { children, className } = props;

  return <Div className={className}>{children}</Div>;
};

export default ContentCustom;
