import { ReactElement, useState } from "react";
import ClassName from "../class/ClassName";

interface IParagraph {
  children?: ReactElement | ReactElement[];
  value?: string;
  className?: string[] | string;
}

const Paragraph = (props: IParagraph): ReactElement<HTMLParagraphElement> => {
  const { children, value, className } = props;
  const [classNames] = useState<ClassName>(new ClassName());

  return (
    <p className={classNames.transformArrayToString({ className })}>
      {children ?? value}
    </p>
  );
}

export default Paragraph;