import GuideIcon from "../components/svg/GuideIcon";

const menu404 = [
  {
    title: "Guía Informativa",
    uri: "/guia-informativa",
    description:
      "Canal dedicado a proporcionar una plataforma segura y accesible para la denuncia de cualquier violación a la Ley Karin",
    icon: (
      <GuideIcon
        className="icon-list"
        width={24}
        height={24}
        fill="rgb(156, 163, 175)"
      />
    ),
  },
  {
    title: "Canal de Denuncias",
    uri: "/canal-de-denuncias",
    description:
      "Canal dedicado a proporcionar una plataforma segura y accesible para la denuncia de cualquier violación a la Ley Karin",
    icon: (
      <GuideIcon
        className="icon-list"
        width={24}
        height={24}
        fill="rgb(156, 163, 175)"
      />
    ),
  },
];

export default menu404;