import { ReactElement, useEffect, useState } from "react";
import ContentCustom from "../components/ContentCustom";
import JsonMenuError from "../json/menu404.json";
import LinkRedirect from "../components/LinkRedirect";
import GridMenuError from "../components/grid/GridMenuError";


const MenuError404 = (): ReactElement<HTMLDivElement> => {
  return (
    <>
      <ContentCustom className="menu-error-404">
        <GridMenuError listMenu={JsonMenuError} />
      </ContentCustom>
      <ContentCustom className="go-to-home">
        <LinkRedirect title="Volver al inicio" uri="/" direction="left" />
      </ContentCustom>
    </>
  );
};

export default MenuError404;