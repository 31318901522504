import { ReactElement } from "react";
import ContentLeft from "../components/ContentLeft";
import ContentRight from "../components/ContentRight";
import LinkRedirect from "../components/LinkRedirect";
import LandingIlus from "../assets/images/landing-guia.png";
import ContentCustom from "../components/ContentCustom";
import Title from "../components/Title";
import Paragraph from "../components/Paragraph";

const EducationalGuideLanding = (): ReactElement<HTMLDivElement> => {
  return (
    <ContentCustom className="landing">
      <ContentLeft>
        <Title
          type="h2"
          className="title"
          value="Guía educativa sobre la Ley Karin"
        />
        <Paragraph
          className="description"
          value="En el marco de la entrada en vigencia de la Ley N° 21.643 popularmente
          conocida como Ley Karin, que modifica el Código del Trabajo en materia
          de prevención, investigación y sanción del acoso laboral, sexual y
          violencia en el trabajo, le recomendamos revisar el siguiente material
          educativo para comprender los alcances de esta normativa."
        />
        <ContentCustom className="link-reditect">
          <LinkRedirect uri="/guia-educativa" title="Revisar guía educativa" />
        </ContentCustom>
      </ContentLeft>
      <ContentRight>
        <img src={LandingIlus} width={1000} />
      </ContentRight>
    </ContentCustom>
  );
};

export default EducationalGuideLanding;