import { ReactElement } from "react";

interface IItemList {
  children?: ReactElement | ReactElement[];
  value?: string;
}

const ItemList = (props: IItemList): ReactElement<HTMLLIElement> => {
  const { children, value } = props;

  return <li>{children ?? value}</li>;
};

export default ItemList;