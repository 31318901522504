import { ITransformArrayToString, IClassName } from "../interfaces/className.interface";

class ClassName implements IClassName {
  constructor() {}

  transformArrayToString = (prompts: ITransformArrayToString): string => {
    const { className }: ITransformArrayToString = prompts;

    console.log({ className, type: typeof className   });

    if (typeof className != "object") {
      return typeof className == "string" ? className : "";
    }
 
    return className.length ? String(className).split(",").join(" ") : "";
  };
}

export default ClassName;