import { ReactElement } from "react";
import { IItemMenu } from "../interfaces/card.interface";
import CustomLink from "./CustomLink";

const ItemMenu = (props: IItemMenu): ReactElement<HTMLLinkElement> => {
  const { uri, title, className, target } = props;

  return (
    <CustomLink uri={uri} className={className} title={title} target={target} />
  );
};

export default ItemMenu;
