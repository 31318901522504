import { ReactElement, useEffect, useState, useRef } from "react";
import { DARK, LIGHT } from "../dictionary/theme.dictionary";
import LightModeIcon from "./svg/LightModeIcon";
import DarkModeIcon from "./svg/DarkModeIcon";
import Button from "./Button";
import DarkModeIndicator from "./DarkModeIndicator";

interface IDarkMode {
  theme?: typeof DARK | typeof LIGHT;
  saveChangeTheme: () => void;
}

const DarkMode = (props: IDarkMode): ReactElement<HTMLElement> => {
  const { theme, saveChangeTheme } = props;
  const [themeMode, setThemeMode] = useState<
    typeof DARK | typeof LIGHT | undefined
  >(undefined);
  const refCircleDarkMode = useRef<HTMLElement>(null);

  useEffect(() => {
    setThemeMode(theme);
  }, [theme]);

  const handleClickDarkModeToogle = (): void => {
    saveChangeTheme();
    setThemeMode(themeMode === LIGHT ? DARK : LIGHT);
    const animationTime: number = 500;
    const animationPosition: string[] = ["5px", "22px"];

    if (refCircleDarkMode) {
      const spanCircleDarkMode = refCircleDarkMode.current;
      if (spanCircleDarkMode) {
        spanCircleDarkMode.animate(
          {
            left:
              themeMode === LIGHT
                ? animationPosition
                : animationPosition.reverse(),
          },
          { duration: animationTime, easing: "ease-in-out" }
        );
      }
    }
  };

  return (
    <>
      <LightModeIcon fill="#0054B9" width={24} height={24} />
      <Button className="button-dark-mode" onClick={handleClickDarkModeToogle}>
        <DarkModeIndicator dark={themeMode === DARK} ref={refCircleDarkMode} />
      </Button>
      <DarkModeIcon fill="#0054B9" width={24} height={24} />
    </>
  );
};

export default DarkMode;
