import { ReactElement, useState } from "react";
import ClassName from "../../class/ClassName";
import { IIConSvg } from "../../interfaces/icon.interface";

const MediaIcon = (props: IIConSvg): ReactElement<HTMLOrSVGElement> => {
  const { width, height, fill, className } = props;
  const [classNames] = useState<ClassName>(new ClassName());

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      className={classNames.transformArrayToString({ className })}
    >
      <path
        fill={fill}
        d="m7.5 19.5l4-2.5l-4-2.5zM13 7.75h4v-1.5h-4zM3 23q-.825 0-1.412-.587T1 21v-8q0-.825.588-1.412T3 11h12q.825 0 1.413.588T17 13v8q0 .825-.587 1.413T15 23zm15.5-9v-1q0-.95-.462-1.75T16.8 10h.2q1.25 0 2.125-.875T20 7t-.875-2.125T17 4h-1.25v1.5H17q.625 0 1.063.438T18.5 7t-.437 1.063T17 8.5h-1.25V10q-.2 0-.375-.25T15 9.5h-.75v-1H13q-.625 0-1.062-.437T11.5 7t.438-1.062T13 5.5h1.25V4H13q-1.25 0-2.125.875T10 7q0 .8.375 1.438T11.35 9.5H7V3q0-.825.588-1.412T9 1h12q.825 0 1.413.588T23 3v9q0 .825-.587 1.413T21 14z"
      />
    </svg>
  );
};

export default MediaIcon;