import { ReactElement, useEffect, useState } from "react";
import { IListMenuError } from "../../interfaces/grid.interface";
import CardMenuError from "../CardMenuError";

interface IGridMenuError {
  listMenu: IListMenuError[];
}

const GridMenuError = (props: IGridMenuError): ReactElement<HTMLElement> => {
  const { listMenu } = props;
  const [list, setList] = useState<IListMenuError[]>([]);

  useEffect(() => {
    if (typeof listMenu == "object") {
      setList(listMenu);
    }

    return () => {
      setList([]);
    };
  }, [listMenu]);

  return (
    <>
      {list.map((item: IListMenuError, key: number) => (
        <CardMenuError
          key={key}
          title={item.title}
          description={item.description}
          icon={item.icon}
          uri={item.uri}
        />
      ))}
    </>
  );
};

export default GridMenuError;