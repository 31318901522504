import { ReactElement } from "react";
import ContentCustom from "./ContentCustom";
import ContentLeft from "./ContentLeft";
import ContentRight from "./ContentRight";
import Paragraph from "./Paragraph";
import ArrowIcon from "./svg/ArrowIcon";
import Title from "./Title";
import CustomLink from "./CustomLink";

interface ICardMenuError {
  title: string;
  description: string;
  icon: ReactElement<HTMLOrSVGElement>;
  uri: string;
}

const CardMenuError = (props: ICardMenuError): ReactElement<HTMLDivElement> => {
  const { title, description, uri, icon } = props;

  return (
    <ContentCustom className="card-error-link">
      <CustomLink uri={uri} className="card-link-background">
        <ContentLeft>{icon}</ContentLeft>
        <ContentRight>
          <Title className="title" type="h3" value={title} />
          <Paragraph className="description" value={description} />
        </ContentRight>
        <ArrowIcon width={20} height={20} fill="rgb(156, 163, 175)" />
      </CustomLink>
    </ContentCustom>
  );
};

export default CardMenuError;