import { forwardRef, LegacyRef, ReactElement } from "react";
import Span from "./Span";

interface IDarkModeIndicator {
  dark?: boolean;
}

const DarkModeIndicator = (
  props: IDarkModeIndicator,
  ref: LegacyRef<HTMLElement>
): ReactElement<HTMLElement> => {
  const { dark } = props;

  return (
    <Span
      ref={ref}
      className={[
        "circle-selected",
        `dark-mode-${!dark ? "disabled" : "enabled"}`,
      ]}
    ></Span>
  );
};

export default forwardRef(DarkModeIndicator);