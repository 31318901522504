import { ReactElement, useState } from "react";
import ClassName from "../class/ClassName";
import { TARGET_BLANK } from "../dictionary/link.dictorionary";

interface ILink {
  uri?: string;
  title?: string;
  className?: string[] | string;
  children?: ReactElement[] | ReactElement;
  target?: typeof TARGET_BLANK;
}

const CustomLink = (props: ILink): ReactElement<HTMLLinkElement> => {
  const { uri, title, children, className, target } = props;
  const [classNames] = useState<ClassName>(new ClassName());

  return (
    <a
      href={uri ?? "#"}
      className={classNames.transformArrayToString({
        className,
      })}
      target={target}
    >
      {children ?? title}
    </a>
  );
};

export default CustomLink;