import { ReactElement, useState } from "react";
import { IIConSvg } from "../../interfaces/icon.interface";
import ClassName from "../../class/ClassName";

const DownloadIcon = (props: IIConSvg): ReactElement<HTMLOrSVGElement> => {
  const { width, height, fill, className } = props;
  const [classNames] = useState<ClassName>(new ClassName());

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames.transformArrayToString({
        className: className ?? [],
      })}
    >
      <path
        d="M11.0625 6H12.9375C13.2492 6 13.5 6.25078 13.5 6.5625V10.5H15.5555C15.9727 10.5 16.1812 11.0039 15.8859 11.2992L12.3211 14.8664C12.1453 15.0422 11.857 15.0422 11.6812 14.8664L8.11172 11.2992C7.81641 11.0039 8.025 10.5 8.44219 10.5H10.5V6.5625C10.5 6.25078 10.7508 6 11.0625 6ZM18 14.8125V17.4375C18 17.7492 17.7492 18 17.4375 18H6.5625C6.25078 18 6 17.7492 6 17.4375V14.8125C6 14.5008 6.25078 14.25 6.5625 14.25H10.0008L11.1492 15.3984C11.6203 15.8695 12.3797 15.8695 12.8508 15.3984L13.9992 14.25H17.4375C17.7492 14.25 18 14.5008 18 14.8125ZM15.0938 16.875C15.0938 16.6172 14.8828 16.4062 14.625 16.4062C14.3672 16.4062 14.1562 16.6172 14.1562 16.875C14.1562 17.1328 14.3672 17.3438 14.625 17.3438C14.8828 17.3438 15.0938 17.1328 15.0938 16.875ZM16.5938 16.875C16.5938 16.6172 16.3828 16.4062 16.125 16.4062C15.8672 16.4062 15.6562 16.6172 15.6562 16.875C15.6562 17.1328 15.8672 17.3438 16.125 17.3438C16.3828 17.3438 16.5938 17.1328 16.5938 16.875Z"
        fill={fill}
      />
    </svg>
  );
};

export default DownloadIcon;