import { ReactElement } from "react";
import MenuError404 from "../layout/MenuError404";
import PrincipalError404 from "../layout/PrincipalError404";
import MenuMode from "../layout/MenuMode";

const Error404 = (): ReactElement<HTMLElement> => {
  return (
    <>
      <MenuMode titlePage="Página no encontrada - Teamwork Chile" />
      <PrincipalError404 />
      <MenuError404 />
    </>
  );
};

export default Error404;
