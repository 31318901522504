import { ReactElement, useState } from "react";
import { IIConSvg } from "../../interfaces/icon.interface";
import ClassName from "../../class/ClassName";

const LightModeIcon = (props: IIConSvg): ReactElement<HTMLOrSVGElement> => {
  const { width, height, fill, className } = props;
  const [classNames] = useState<ClassName>(new ClassName());

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames.transformArrayToString({
        className: className ?? [],
      })}
    >
      <path
        d="M12 15.2727C12.9091 15.2727 13.6818 14.9545 14.3182 14.3182C14.9545 13.6818 15.2727 12.9091 15.2727 12C15.2727 11.0909 14.9545 10.3182 14.3182 9.68182C13.6818 9.04545 12.9091 8.72727 12 8.72727C11.0909 8.72727 10.3182 9.04545 9.68182 9.68182C9.04545 10.3182 8.72727 11.0909 8.72727 12C8.72727 12.9091 9.04545 13.6818 9.68182 14.3182C10.3182 14.9545 11.0909 15.2727 12 15.2727ZM12 17.4545C10.4909 17.4545 9.20455 16.9227 8.14091 15.8591C7.07727 14.7955 6.54545 13.5091 6.54545 12C6.54545 10.4909 7.07727 9.20455 8.14091 8.14091C9.20455 7.07727 10.4909 6.54545 12 6.54545C13.5091 6.54545 14.7955 7.07727 15.8591 8.14091C16.9227 9.20455 17.4545 10.4909 17.4545 12C17.4545 13.5091 16.9227 14.7955 15.8591 15.8591C14.7955 16.9227 13.5091 17.4545 12 17.4545ZM4.36364 13.0909H0V10.9091H4.36364V13.0909ZM24 13.0909H19.6364V10.9091H24V13.0909ZM10.9091 4.36364V0H13.0909V4.36364H10.9091ZM10.9091 24V19.6364H13.0909V24H10.9091ZM5.89091 7.36364L3.13636 4.71818L4.69091 3.10909L7.30909 5.83636L5.89091 7.36364ZM19.3091 20.8909L16.6636 18.1364L18.1091 16.6364L20.8636 19.2818L19.3091 20.8909ZM16.6364 5.89091L19.2818 3.13636L20.8909 4.69091L18.1636 7.30909L16.6364 5.89091ZM3.10909 19.3091L5.86364 16.6636L7.36364 18.1091L4.71818 20.8636L3.10909 19.3091Z"
        fill={fill}
      />
    </svg>
  );
};

export default LightModeIcon;