import { forwardRef, LegacyRef, ReactElement, useState } from "react";
import ClassName from "../class/ClassName";

interface ISpan {
  children?: ReactElement | ReactElement[];
  className?: string | string[];
}

const Span = (
  props: ISpan,
  ref: LegacyRef<HTMLElement>
): ReactElement<HTMLElement> => {
  const { children, className } = props;
  const [classNames] = useState<ClassName>(new ClassName());

  return (
    <span
      className={classNames.transformArrayToString({ className })}
      ref={ref}
    >
      {children}
    </span>
  );
};

export default forwardRef(Span);