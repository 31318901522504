import { ReactElement, useState } from "react";
import ClassName from "../../class/ClassName";
import { IIConSvg } from "../../interfaces/icon.interface";

const ChannelIcon = (props: IIConSvg): ReactElement<HTMLOrSVGElement> => {
  const { width, height, fill, className } = props;
  const [classNames] = useState<ClassName>(new ClassName());

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      className={classNames.transformArrayToString({ className })}
    >
      <path
        fill={fill}
        d="M2 22V9q0-.825.588-1.413Q3.175 7 4 7h2V4q0-.825.588-1.413Q7.175 2 8 2h12q.825 0 1.413.587Q22 3.175 22 4v8q0 .825-.587 1.412Q20.825 14 20 14h-2v3q0 .825-.587 1.413Q16.825 19 16 19H5Zm6-10h8V9H8Zm-4 5h12v-3H8q-.825 0-1.412-.588Q6 12.825 6 12V9H4Zm14-5h2V4H8v3h8q.825 0 1.413.587Q18 8.175 18 9Z"
      />
    </svg>
  );
};

export default ChannelIcon;