import { ReactElement, ReactNode, useState } from "react";
import { H1, H2, H3, H4, H5, H6 } from "../dictionary/title.dictionary";
import ClassName from "../class/ClassName";

interface ITitle {
  value?: string;
  children?: ReactElement | ReactElement[];
  className?: string[] | string;
  type?: typeof H1 | typeof H2 | typeof H3 | typeof H4 | typeof H5 | typeof H6;
}

interface ITypeTitle {
  [H1]: (prompts: ITitle) => ReactElement<HTMLTitleElement>;
  [H2]: (prompts: ITitle) => ReactElement<HTMLTitleElement>;
  [H3]: (prompts: ITitle) => ReactElement<HTMLTitleElement>;
  [H4]: (prompts: ITitle) => ReactElement<HTMLTitleElement>;
  [H5]: (prompts: ITitle) => ReactElement<HTMLTitleElement>;
  [H6]: (prompts: ITitle) => ReactElement<HTMLTitleElement>;
}

const Title = (props: ITitle): ReactElement<HTMLTitleElement> => {
  const { value, children, className, type } = props;
  const [classNames] = useState<ClassName>(new ClassName());
  const [typeTitle] = useState<ITypeTitle>({
    [H1]: (prompts: ITitle) => (
      <h1
        className={classNames.transformArrayToString({
          className,
        })}
      >
        {prompts.children ?? prompts.value}
      </h1>
    ),
    [H2]: (prompts: ITitle) => (
      <h2
        className={classNames.transformArrayToString({
          className,
        })}
      >
        {prompts.children ?? prompts.value}
      </h2>
    ),
    [H3]: (prompts: ITitle) => (
      <h3
        className={classNames.transformArrayToString({
          className,
        })}
      >
        {prompts.children ?? prompts.value}
      </h3>
    ),
    [H4]: (prompts: ITitle) => (
      <h4
        className={classNames.transformArrayToString({
          className,
        })}
      >
        {prompts.children ?? prompts.value}
      </h4>
    ),
    [H5]: (prompts: ITitle) => (
      <h5
        className={classNames.transformArrayToString({
          className,
        })}
      >
        {prompts.children ?? prompts.value}
      </h5>
    ),
    [H6]: (prompts: ITitle) => (
      <h6
        className={classNames.transformArrayToString({
          className,
        })}
      >
        {prompts.children ?? prompts.value}
      </h6>
    ),
  });
  
  return typeTitle[type ?? H1]({ value, children, className });
};

export default Title;