import { ReactElement, useState } from "react";
import { IIConSvg } from "../../interfaces/icon.interface";
import ClassName from "../../class/ClassName";

const DarkModeIcon = (props: IIConSvg): ReactElement<HTMLOrSVGElement> => {
  const { width, height, fill, className } = props;
  const [classNames] = useState<ClassName>(new ClassName());

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames.transformArrayToString({
        className: className ?? [],
      })}
    >
      <path
        d="M12 24C8.66667 24 5.83333 22.8333 3.5 20.5C1.16667 18.1667 0 15.3333 0 12C0 8.66667 1.16667 5.83333 3.5 3.5C5.83333 1.16667 8.66667 0 12 0C12.3111 0 12.6167 0.0111111 12.9167 0.0333333C13.2167 0.0555556 13.5111 0.0888889 13.8 0.133333C12.8889 0.777778 12.1611 1.61667 11.6167 2.65C11.0722 3.68333 10.8 4.8 10.8 6C10.8 8 11.5 9.7 12.9 11.1C14.3 12.5 16 13.2 18 13.2C19.2222 13.2 20.3444 12.9278 21.3667 12.3833C22.3889 11.8389 23.2222 11.1111 23.8667 10.2C23.9111 10.4889 23.9444 10.7833 23.9667 11.0833C23.9889 11.3833 24 11.6889 24 12C24 15.3333 22.8333 18.1667 20.5 20.5C18.1667 22.8333 15.3333 24 12 24ZM12 21.3333C13.9556 21.3333 15.7111 20.7944 17.2667 19.7167C18.8222 18.6389 19.9556 17.2333 20.6667 15.5C20.2222 15.6111 19.7778 15.7 19.3333 15.7667C18.8889 15.8333 18.4444 15.8667 18 15.8667C15.2667 15.8667 12.9389 14.9056 11.0167 12.9833C9.09444 11.0611 8.13333 8.73333 8.13333 6C8.13333 5.55556 8.16667 5.11111 8.23333 4.66667C8.3 4.22222 8.38889 3.77778 8.5 3.33333C6.76667 4.04444 5.36111 5.17778 4.28333 6.73333C3.20556 8.28889 2.66667 10.0444 2.66667 12C2.66667 14.5778 3.57778 16.7778 5.4 18.6C7.22222 20.4222 9.42222 21.3333 12 21.3333Z"
        fill={fill}
      />
    </svg>
  );
};

export default DarkModeIcon;