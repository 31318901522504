import { DARK, LIGHT, STORAGE_THEME } from "../dictionary/theme.dictionary";

class Theme {
  private theme: typeof DARK | typeof LIGHT = LIGHT;

  constructor() {
    this.getThemeStorage();
  }

  getTheme = (): typeof DARK | typeof LIGHT => {
    return this.theme;
  };

  setTheme = (themeMode: typeof DARK | typeof LIGHT): void => {
    this.theme = themeMode;
  };

  getThemeStorage = (): void => {
    const theme =
      (localStorage.getItem(STORAGE_THEME) as typeof DARK | typeof LIGHT) ??
      LIGHT;

    this.setTheme(theme);
  };

  saveToggleThemeStorage = (): void => {
    const body = document.querySelector("body");

    if (body) {
      const isDarkMode: boolean = body.classList.contains(DARK);
      const themeMode = isDarkMode ? LIGHT : DARK;
      body.classList.add(themeMode);
      body.classList.remove(isDarkMode ? DARK : LIGHT);
      body.style.transition = "all .5s";
      localStorage.setItem(STORAGE_THEME, themeMode);
      this.setTheme(themeMode);
    }
  };
}

export default Theme;