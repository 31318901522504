import { ReactElement } from "react";
import ArrowIcon from "./svg/ArrowIcon";

interface ICard {
  title: string;
  description: string;
}

const CardGlossary = (props: ICard): ReactElement<HTMLDivElement> => {
  const { title, description } = props;

  return (
    <div className="card">
      <h3 className="card-title">{title}</h3>
      <p className="card-description">{description}</p>
      <ArrowIcon
        width={24}
        height={24}
        fill="#0054B9"
        className={["card-arrow"]}
      />
    </div>
  );
};

export default CardGlossary;