import { ReactElement } from "react";

const Home = (): ReactElement<HTMLElement> => {
  return (
    <section id="guia-educativa" className="home">
      <h1 className="title">
        Guía educativa sobre la
        <br />
        Ley Karin
      </h1>
      <p>
        En el marco de la entrada en vigencia de la Ley N° 21.643 popularmente
        conocida como Ley Karin, que modifica el Código del Trabajo en materia
        de prevención, investigación y sanción del acoso laboral, sexual y
        violencia en el trabajo, le recomendamos revisar el siguiente material
        educativo para comprender los alcances de esta normativa.
      </p>
    </section>
  );
}

export default Home;