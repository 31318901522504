import { ReactElement, useState } from "react";
import ClassName from "../class/ClassName";

interface IButton {
  children?: ReactElement | ReactElement[];
  title?: string;
  className?: string | string[];
  onClick?: () => void;
}

const Button = (props: IButton): ReactElement<HTMLButtonElement> => {
  const { children, title, className, onClick } = props;
  const [classNames] = useState<ClassName>(new ClassName());

  return (
    <button
      className={classNames.transformArrayToString({ className })}
      onClick={onClick}
    >
      {children ?? title}
    </button>
  );
};

export default Button;