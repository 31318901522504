import { ReactElement, useState, useRef } from "react";
import { IAcademicCard } from "../interfaces/card.interface";
import DownloadIcon from "./svg/DownloadIcon";
import RedirectIcon from "./svg/RedirectIcon";
import ArrowIcon from "./svg/ArrowIcon";

interface IHandleClickDownloadFile {
  blob: string;
  name: string;
}

const CardAcademicModule = (
  props: IAcademicCard
): ReactElement<HTMLDivElement> => {
  const { title, indexModule, course, id, pdf } = props;
  const refCard = useRef<HTMLSpanElement>(null);
  const refCardCollapse = useRef<HTMLDivElement>(null);
  const refArrowImg = useRef<SVGSVGElement>(null);
  const refCardHeader = useRef<HTMLDivElement>(null);
  const [isCollapse, setIsCollapse] = useState<boolean>(true);

  const handleClickCollapseToggle = (): void => {
    const spanCard = refCard.current;
    const divCard = refCardCollapse.current;
    const imgArrow = refArrowImg.current;
    const timeAnimation: number = 500;
    const divCardHeader = refCardHeader.current;

    if (spanCard && divCard && imgArrow && divCardHeader) {
      const { height: heigthCard } = spanCard.getBoundingClientRect();
      const { height: heightCardHeader } =
        divCardHeader.getBoundingClientRect();

      divCard.animate(
        {
          height: isCollapse
            ? [`${heightCardHeader}px`, `${heigthCard}px`]
            : [`${heigthCard}px`, `${heightCardHeader}px`],
        },
        {
          duration: timeAnimation,
          easing: "ease-in-out",
        }
      );
      imgArrow.animate(
        {
          transform: isCollapse
            ? ["rotate(90deg)", "rotate(270deg)"]
            : ["rotate(270deg)", "rotate(90deg)"],
        },
        {
          duration: timeAnimation,
          easing: "ease-in-out",
        }
      );
      setTimeout(() => {
        divCard.style.height = isCollapse
          ? `${heigthCard}px`
          : `${heightCardHeader}px`;
        imgArrow.style.transform = isCollapse
          ? "rotate(270deg)"
          : "rotate(90deg)";
        setIsCollapse(!isCollapse);
      }, timeAnimation);
    }
  };

  const handleClickDownloadFile = (prompts: IHandleClickDownloadFile): void => {
    const { blob, name } = prompts;
    const a = document.createElement("a");
    a.setAttribute("download", name);
    a.setAttribute("href", blob);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div id={id} className="card-academic-module" ref={refCardCollapse}>
      <span ref={refCard}>
        <div
          className="card-academic-module-header"
          onClick={() => handleClickCollapseToggle()}
          ref={refCardHeader}
        >
          <p>
            <b>{indexModule}:</b> {title}
          </p>
          <ArrowIcon
            width={24}
            height={24}
            fill="#0054B9"
            className={["card-arrow"]}
            ref={refArrowImg}
          />
        </div>
        <div className="card-academic-module-body">
          <div className="box-actions">
            <button
              className="card-button"
              onClick={() => handleClickDownloadFile({ ...pdf })}
            >
              Descargar contenido{" "}
              <DownloadIcon width={24} height={24} fill="#0054B9" />
            </button>
            <a
              href={course.uri}
              target="_blank"
              rel="noreferrer"
              className="card-button button-redirect"
            >
              Revisar contenido multimedia
              <RedirectIcon width={24} height={24} fill="#0054B9" />
            </a>
          </div>
          <p className="content-multi-text">
            Puedes revisar el contenido multimedia del <b>{indexModule}:</b>{" "}
            {title}
          </p>
          <iframe
            title={course.name}
            src={course.uri}
            frameBorder={0}
            scrolling="yes"
            allowFullScreen={true}
          ></iframe>
        </div>
      </span>
    </div>
  );
};

export default CardAcademicModule;
