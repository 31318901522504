import { ReactElement } from "react";
import Div from "../components/Div";
import Error404Img from "../assets/images/error-404.svg";
import Title from "../components/Title";
import Paragraph from "../components/Paragraph";

const PrincipalError404 = (): ReactElement<HTMLDivElement> => {
  return (
    <Div className="error-404">
      <img src={Error404Img} width={150} alt="Error 404" />
      <Title className="title" value="Esta página no existe" />
      <Paragraph
        className="description"
        value="Lo sentimos, no pudimos encontrar la página que estás buscando."
      />
    </Div>
  );
};

export default PrincipalError404;