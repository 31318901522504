import { ReactElement, useState } from "react";
import { IIConSvg } from "../../interfaces/icon.interface";
import ClassName from "../../class/ClassName";

const RedirectIcon = (props: IIConSvg): ReactElement => {
  const { width, height, fill, className } = props;
  const [classNames] = useState<ClassName>(new ClassName());

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames.transformArrayToString({
        className: className ?? [],
      })}
    >
      <path
        d="M12 17.5563L10.9312 16.5839L15.1312 12.6945H6V11.3055H15.1312L10.9312 7.41607L12 6.44373L18 12L12 17.5563Z"
        fill={fill}
      />
    </svg>
  );
};

export default RedirectIcon;