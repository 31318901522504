import { ReactElement } from "react";
import LinkRedirect from "../components/LinkRedirect";
import ContentLeft from "../components/ContentLeft";
import ContentRight from "../components/ContentRight";
import ContentCustom from "../components/ContentCustom";
import ReportIlus from "../assets/images/landing-denuncias.png";
import Title from "../components/Title";
import CustomLink from "../components/CustomLink";
import Paragraph from "../components/Paragraph";

const ReportLanding = (): ReactElement<HTMLDivElement> => {
  return (
    <ContentCustom className="report">
      <ContentLeft>
        <img
          src={ReportIlus}
          width={600}
          className="img-report-chanell"
          alt="Imagen report channel"
        />
      </ContentLeft>
      <ContentRight>
        <Title type="h2" className="title" value="Canal de denuncias" />
        <Paragraph
          className="description"
          value="Este canal está dedicado a proporcionar una plataforma segura y
          accesible para la denuncia de cualquier violación a la Ley Karin, la
          legislación diseñada para proteger y garantizar los derechos de todos
          los ciudadanos. En él, puedes presentar informes sobre casos de abuso,
          discriminación o cualquier otra infracción que contravenga las
          disposiciones de esta ley."
        />
        <Paragraph className="contact">
          <>
            Escribenos al mail{" "}
            <CustomLink
              className="link-mail"
              title="teapoyo@team-work.cl"
              uri="mailto:teapoyo@team-work.cl"
            />{" "}
            o ingresa directamente la denuncia en el enlace que se encuentra a
            continuación
          </>
        </Paragraph>
        <ContentCustom className="control-button">
          <CustomLink
            title="Denunciar"
            uri="https://teamwork.buk.cl/cul_partner_complaint/tickets/new"
            className="button-report"
          />
          <LinkRedirect
            uri="mailto:teapoyo@team-work.cl"
            title="Escribenos al mail"
          />
        </ContentCustom>
      </ContentRight>
    </ContentCustom>
  );
};

export default ReportLanding;