import { ReactElement } from "react";
import MenuMode from "../layout/MenuMode";
import Footer from "../layout/Footer";
import Title from "../components/Title";
import ContentCustom from "../components/ContentCustom";
import JsonInformativeGuide from "../json/informativeGuide.json";
import InformativeGuideParagraphs from "../layout/InformativeGuideParagraphs";

const InformativeGuide = (): ReactElement<HTMLDivElement> => {
  return (
    <>
      <MenuMode titlePage="Guía informativa ley Karin - Teamwork Chile" />
      <ContentCustom className="content-informative-guide">
        <label className="legends">{JsonInformativeGuide.legends}</label>
        <Title className="title" value={JsonInformativeGuide.title} />
        <InformativeGuideParagraphs
          paragraphs={JsonInformativeGuide.paragraphs}
        />
      </ContentCustom>
      <Footer />
    </>
  );
};

export default InformativeGuide;
