import { ReactElement, useEffect, useState } from "react";
import JsonAcademic from "../json/academic.json";
import { IAcademicCard } from "../interfaces/card.interface";
import CardAcademicModule from "../components/CardAcademicModule";

const AcademicModules = (): ReactElement<HTMLElement> => {
  const [cardsAcademic, setCardAcademic] = useState<IAcademicCard[]>([]);

  useEffect(() => {
    if (JsonAcademic) {
      setCardAcademic(JsonAcademic);
    }
  }, []);

  return (
    <section className="academic-modules">
      <h3 className="subtitle">Módulos material educativo</h3>
      <h2 className="title">
        Material educativo clave para comprender el alcance de esta normativa
      </h2>
      <p className="description">
        A continuación podrás acceder al material educativo segmentado por
        módulos y podrás descargar el archivo PDF con el material completo.{" "}
        <br />
        <b>Módulo I:</b> Fundamentos e impacto del acoso laboral, acoso sexual y
        la violencia en el trabajo, <b>Módulo II:</b> Identificación y
        prevención del acoso y la violencia en el trabajo, <b>Modelo III:</b>{" "}
        Marco legal y normativo en el entorno laboral (Ley Karin) y{" "}
        <b>Módulo IV:</b> Protocolo prevención y acción
      </p>
      {cardsAcademic.map((card: IAcademicCard, key: number) => (
        <CardAcademicModule
          key={key}
          pdf={card.pdf}
          id={card.id}
          title={card.title}
          indexModule={card.indexModule}
          course={card.course}
        />
      ))}
    </section>
  );
};

export default AcademicModules;