import { forwardRef, LegacyRef, ReactElement, useState } from "react";
import { IIConSvg } from "../../interfaces/icon.interface";
import ClassName from "../../class/ClassName";

const ArrowIcon = (
  props: IIConSvg,
  ref: LegacyRef<SVGSVGElement>
): ReactElement<SVGSVGElement> => {
  const { width, height, fill, className } = props;
  const [classNames] = useState<ClassName>(new ClassName());

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames.transformArrayToString({
        className: className ?? [],
      })}
      ref={ref}
    >
      <path
        d="M7.0202 18.1176L13.1743 12L7.0202 5.88235L8.91726 4L16.9798 12L8.91726 20L7.0202 18.1176Z"
        fill={fill}
      />
    </svg>
  );
};

export default forwardRef(ArrowIcon);