import { ReactElement, useState } from "react";
import { IIConSvg } from "../../interfaces/icon.interface";
import ClassName from "../../class/ClassName";

const HomeIcon = (props: IIConSvg): ReactElement<HTMLOrSVGElement> => {
  const { width, height, fill, className } = props;
  const [classNames] = useState<ClassName>(new ClassName());

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 256 256`}
      className={classNames.transformArrayToString({ className })}
    >
      <path
        fill={fill}
        d="M224 120v96a8 8 0 0 1-8 8h-56a8 8 0 0 1-8-8v-52a4 4 0 0 0-4-4h-40a4 4 0 0 0-4 4v52a8 8 0 0 1-8 8H40a8 8 0 0 1-8-8v-96a16 16 0 0 1 4.69-11.31l80-80a16 16 0 0 1 22.62 0l80 80A16 16 0 0 1 224 120"
      />
    </svg>
  );
};

export default HomeIcon;