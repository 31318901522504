import { ReactElement } from "react";
import { IContentCustomEmpty } from "../interfaces/content.interface";
import Div from "./Div";

const ContentCustomEmpty = (
  props: IContentCustomEmpty
): ReactElement<HTMLDivElement> => {
  const { className, onClick } = props;

  return <Div className={className} onClick={onClick} />;
};

export default ContentCustomEmpty;
